@import "../../global.scss";

.menu {
  width: 100%;
  height: 100%;
  background-color: transparent;
  outline: 0;
  border: 0;
  position: relative;
  font-size: 14px;
  color: var(--themecolor);

  .closerow {
    display: none;
  }
  .logo {
    height: 79px;
    margin-top: 24px;
    margin-left: 16px;
    margin-bottom: 47px;
  }
}
.hidden {
  display: none;
}
.submenuOpen {
  svg {
    transform: rotate(180deg);
  }
}

.menuitemrow {
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 41px;
  .menuitemicon {
    margin: 0 16px;
    width: 20px;
    height: 20px;
  }
  &:last-child {
    border-color: var(--bordercolor);
    border-width: 1px;
    border-top-style: solid;
  }
}
.current {
  font-weight: 500;
}
.menulink {
  color: var(--themecolor);
}
.menulink,
.logout {
  &:hover {
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
  }
}
.menuitemrow,
.logout {
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 41px;
}
.footer {
  flex-direction: column;
}
.logout {
  font-size: 16px;
  margin-top: 30px;
}
.logout,
.footer {
  padding: 0 16px;
}
.submenuicon {
  display: flex;
  margin:0 16px;
      svg {
        align-self: center;
        width: 20px;
        height:20px;
      }  
}
.menutitle {
  flex-grow: 1;
}
.logouttext {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-color: var(--bordercolor);
  border-width: 1px;
  border-bottom-style: solid;
  border-top-style: solid;
}
.contactbtn {
  margin: 30px 16px;
}
@media (max-width: $breakpoint-med) {
  .logo {
    display: none;
  }
  .logout {
    padding: 0;
    border-color: var(--bordercolor);
    border-width: 1px;
    border-bottom-style: solid;
    margin-top: 0;
  }
  .logouttext {
    border: none;
    margin-left: 16px;
  }
  .closerow {
    display: flex !important;
    justify-content: flex-end;
    height: 66px;
    svg {
      height: 20px;
      width: 21px;
    }
    span {
      margin: 20px;
      cursor: pointer;
    }
  }
  .menuitemrow {
    border-bottom: 1px solid var(--bordercolor);
  }
  .doublecheckbox {
    margin-left: 0;
  }
}
