@import "../../global.scss";
.notificationbar{
    display: none;
    width: 100%;
    min-height: 51px;
}
.notificationcontainer {
    position: fixed;
    width: 100%;
}

.danger,
.success,
.warning,
.info {
  height: 100%;
  width: 100%;
  //padding-left: 82px;
  border: none;
  color: var(--themefontcolor);
  display: flex;
  flex-direction: row;
}
.danger {
  background-color: var(--orange);
}
.success {
    background-color: var(--green);
}
.warning {
  background-color: #fff3cd;
}
.info {
  background-color: #d1ecf1;
}
.notificationmsg {
  flex-grow: 1;
  align-self: center;
}
.cancelbutton {
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 50px;
}
@media (max-width: $breakpoint-med) {
  .danger,
  .success,
  .warning,
  .info {
    padding-left: 20px;
  }
}
