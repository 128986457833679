@import "../../../global.scss";

.custominput {
  background-color: transparent; 
  position: relative;
  display:flex;
  flex-direction:column;
  width:100%;
}
.errorMessage {
    text-overflow: ellipsis;
}
