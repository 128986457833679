@import "../../../variables.scss";

.backbar {
    background-color: var(--bgcolor);
    width: 100%;
    position: fixed;
    margin-top: -10px;
    z-index: 3;
    margin-left: -82px;
}

.canceldiv {
    text-decoration: underline;
    display: flex;
    height: 70px;
    padding: 5px 0;
    padding-left: $container-padding;
    align-items: center;

    span {
        display: flex;
        cursor: pointer;
    }
}

.cancelicon {
    height: 20px;
    width: 20px;
    margin-right: 20px;
    align-self: center;

    path {
        stroke: var(--themecolor);
    }
}

.topborder {
    height: 1px;
    border-top: 1px solid var(--darkbeige);
}

@media (max-width: $breakpoint-med) {
    .backbar {
        margin-left: $container-margin-med;
        font-size: 14px;
        margin-top: 0px;
    }

    .canceldiv {
        padding-left: $container-padding-med;
        margin-top: -20px;
    }

    .topborder {
        margin-left: $container-margin-med;
    }
}
