@import "../../../global.scss";


.regbtncontainer {
 
  button {
    margin-left: 15px;
  }
}
.closebtncontainer,
.regbtncontainer {
  padding-top: 15px;
  text-align: right;
}
