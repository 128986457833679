@import "../../../global.scss";

.searchbox {
  width: 100%;
  padding: 5px;
  position: relative;
  svg {
    position: absolute;
    top: 26px;
    left: 15px;
  }
}

.searchinput {
  width: 100%;
  background-color:#ffffff;
  outline: 0;
  border: 1px solid var(--themecolor);
  padding-left: 25px;
}
