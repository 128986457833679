@import "../../global.scss";


.topbar {
  span {
    color: var(--themecolor);
  }
  display: flex;
  width: 100%;
  height: 80px;
  padding-right: 290px;
  background-color: #ffffff;
  z-index: 2;
  position:fixed;
}
.topbarinner{
  flex-direction: row;
  padding-left: 82px;
  display: flex;
  width: 100%;
}
.logout {
  padding-right: 30px;
  flex-grow: 1;
  text-align: right;
  align-self: center;
}
.title {
  align-self: center;
  font-size: 30px;
}
.userinfo {
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
}
.username {
  font-size: 22px;
}
.username,
.title {
  font-weight: 700;
  line-height: 36px;
  text-align: left;
}
.email {
  margin-left: 30px;
}

.phone,
.link {
  margin-left: 20px;
}

.link {
  text-decoration: underline;
  font-weight: 700;  
  margin-right: 20px;
}

.phonelink {
    flex-direction: row;
    display: flex;
    align-items: center;
    flex-grow: 1;   
}
.email,
.phone,
.link {
  display: flex;
  div {
    width: 20px;
    margin-right: 10px;
    text-align: center;
    display: flex;
    align-items: center;
  }
  min-width: 100px;
}

@media (max-width: $breakpoint-large) and (min-width: $breakpoint-med) {
    .loan {
        display: none;
    }
}

@media (max-width: $breakpoint-med) {
  .topbar {
    height: 149px;
    align-content: flex-start;
    padding-right: 0px;
  }
  .onlytitle {
    height: 60px;
  }
  .topbarinner{
      padding-left: 20px;
  }
  .logout {
    display: none;
  }
  .userinfo {
    flex-grow: 1;
    flex-direction: column;
  }
  .username {
    margin-top: 15px;
    min-height: 36px;
  }
  .phonelink {
    width: 100%;
    flex-grow: 1;
    .link {
      height: 100%;
      align-items: center;
      flex-grow: 1;
      justify-content: flex-end;
      padding-right: 20px;
    }
  }
  .username,
  .email,
  .loan,
  .phone {
    align-self: flex-start;
    margin-left: 0;
  }
  .email,
  .phone {
    margin-top: 10px;
    min-height: 19px;
  }
  .title {
    font-size: 20px;
  }
}
