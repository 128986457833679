@import "../../../global.scss";

td,
th {
  padding-left: 10px;
  color: var(--themecolor);
}
.divider {
  //box-shadow: 0 4px 4px -4px var(--form_box_color);
  border-bottom: 1px solid var(--darkbeige);
}
.bold {
  font-weight: bold;
}
table {
  border-collapse: collapse;
  width: 100%;
}
th {
  text-align: left;
  font-weight: 550;
}
td {
  padding-top: 15px;
  padding-bottom: 15px;
}
.showbuttoncolumn {
  padding-right: 10px;
  text-align: right;
}

.header {
  border-bottom: 1px solid var(--themecolor);
}

.hiddencolumn {
  display: none;
}
.tablediv {
  width: 100%;
}
