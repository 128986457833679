@import "../../global.scss";

.errorMsgContainer,
.content {
    padding-left: 15px;
}
.titlewhite {
    padding: 50px 50px 0px 50px;
    font-weight: 500;
    font-size: larger;
}
.titlecolor {
    padding: 50px 50px 0px 50px;
    color: white;
    font-weight: 500;
    font-size: larger;
}
.white {
    padding: 0px 50px 0px 50px;
    font-weight: 400;
    font-size: medium;
}
.color {
    padding: 0px 50px 0px 50px;
    color: white;
    font-weight: 400;
    font-size: medium;
}

.buttoncontainer {
  padding-top:15px;
  text-align: right;
}
.elementcontent{
    position: relative;
    padding-right: 15px;
    padding-bottom: 15px;
}
.background {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -2;
}

.watermark {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat no-repeat;
    background-position: 115% center;
    background-size: 45%;
    filter: brightness(0) invert(1) brightness(80%); // Make svg white
    opacity: 0.2; // Make svg take the appearance of the background color
}
.ctacolor {
    text-decoration: underline;
    background-color: rgba(255, 255, 255, 0.1);

    &:hover {
        background-color: rgba(255, 255, 255, 0.7);
    }
}
.ctawhite {
    text-decoration: underline;
    background-color: #253143;

    &:hover {
        background-color: #2531437F; // Aplha value 0.5 = 7F
    }
}
@media (max-width: 480px) {
    .watermark {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat no-repeat;
        background-position: 1700% top;
        background-size: 97%;
        filter: brightness(0) invert(1) brightness(80%); // Make svg white
        opacity: 0.2; // Make svg take the appearance of the background color
    }

    .buttoncontainer {
        padding-top: 15px;
        text-align: left;
    }
}