@import "../../global.scss";
.refusion {
    padding:15px 0;
    .title {
        display: flex;
        flex-direction: row;
        h4 {
            display: flex;
        }
    }
    .icon {
        position: relative;
        margin-left: 5px;
    }
}
