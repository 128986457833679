@import "../../../global.scss";
.disabled {
  background-color: var(--disabledcolor) !important;
}
.cta,
.flow,
.upload,
.filter,
.download,
.document {
  height: 38px;
  padding: 0 16px;
  cursor: pointer;
  border-radius: 90px;
  border: 0;
  font-size: 16px;
  min-width: 80px;
}

.cta,
.upload {
  color: var(--themefontcolor);
  svg {
    margin-right: 7px;
  }
}
.filter {
  color: var(--themecolor);
  background-color: var(--darkbeige);
  min-width: auto;
  svg {
    margin-right: 7px;
    width: 16px;
    display: flex;
  }
  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), var(--darkbeige);
  }
}

.flow {
  .flowchildren {
    color: #ffffff;
  }
  svg {
    margin-left: 20px;
  }
}
.circle {
  color: var(--themefontcolor);
  border-radius: 50%;
  height: 20px;
  border: 0;
  width: 20px;
  position: relative;
  margin: 0 16px;
  svg > path {
    fill: var(--themefontcolor);
  }
}
.upload {
  background-color: var(--green) !important;
  color: var(--themefontcolor);
  font-weight: 500;
  .flowchildren {
    color: var(--themefontcolor);
  }
  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), var(--green);
  }
}

.document {
  background-color: var(--darkbeige) !important;
  color: var(--themecolor);
  font-size: 14px;
  .flowchildren {
    color: var(--themecolor);
  }
  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), var(--darkbeige);
  }
}

.submit {
  background-color: var(--themecolor) !important;
  color: var(--themefontcolor);
  .flowchildren {
    color: var(--themefontcolor);
    justify-content: center;
  }
  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), var(--ctabuttoncolor);
  }
}
button {
  &:disabled {
    background: var(--inputbordercolor) !important;
  }
}
.back {
  background-color: var(--flowbackbuttoncolor) !important;
  color: var(--ctabuttoncolor);
  svg {
    margin-right: 20px;
    margin-left: 0px;
  }
  .flowchildren {
    color: var(--ctabuttoncolor);
  }
  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), var(--flowbackbuttoncolor);
  }
}

.circle,
.cta,
.flow {
  background-color: var(--ctabuttoncolor);

  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), var(--ctabuttoncolor);
  }
}

.flowchildren {
  display: flex;
  align-items: center;
}
@media (max-width: $breakpoint-med) {
  .filter {
    .flowchildren {
      span {
        display: none;
      }
      svg {
        margin: 0;
      }
    }
  }
  .flow {
    flex-grow: 1;
    border-radius: 0;
    height: 65px;
    margin: 0 !important;
  }
}

.cta
.infobox {
    background-color: red;
}