@import "../../../global.scss";

.phoneinput{
  display:flex;
  flex-direction: row;
  max-width: 443px;
}
.landcode{
  width:70px;
  margin-right:3px;
  button{
    padding-left: 6px;
  }
  svg{
    margin-right:0 !important;
    margin-left:6px !important;
  }
}
.pluscontainer{
  background-color: $form-bgcolor;
  display: flex;
}
.plus{
  display: flex;
  align-self: center;
}