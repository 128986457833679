@import "../../global.scss";

.welcomeText {
    color: var(--themecolor);
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
}

.shortcut {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    background-color: white;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin: auto;
    padding: 20px 20px 25px 20px;

    > a {
        text-decoration: none;
    }
}

.shortcutinfo {
    color: var(--themecolor);
    display: flex;
    flex-direction: column;
    padding: 5px;
    justify-content: center;
    width: 100%;
    max-width: 100%;
}

.shortcutitem {
    width: 90%;
    max-height: 35px;
    margin: 20px 15px 0px 15px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    border-bottom: 2px solid;
    border-color: var(--darkbeige);
    font-size: 18px;
    font-weight: 500;
}

.shortcutitem {
    transition: 0.3s ease-in-out;
    -webkit-transition: transform 0.3s ease-in-out;
    -moz-transition: transform 0.3s ease-in-out;
    -ms-transition: transform 0.3s ease-in-out;
}

.shortcutitem:hover,
.shortcutitem:hover .shorcuticon,
.shortcutitem:hover .shortcutinfo {
    transition: 0.7s ease-in-out;
    -webkit-transition: transform 0.7s ease-in-out;
    -moz-transition: transform 0.7s ease-in-out;
    -ms-transition: transform 0.7s ease-in-out;
    cursor: pointer;
}

.shorcuticon {
    width: 35px;
    min-width: 35px;
    height: 35px;
    display: flex;
    align-self: center;
    margin-right: 15px;
}

.rightarrow {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bottomlink {
    background-color: white;
    width: 100%;
    height: 50px;
    text-align: center;
    margin: auto;
    text-decoration: underline;
}

.bottomlink > a {
    color: var(--darkturquoise);
}

@media (max-width: 1300px) {
    .shortcut {
        display: grid;
        grid-template-columns: 1fr;
        background-color: white;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        margin: auto;
        padding: 15px 20px 25px 20px;
    }
}
