@import "../../../global.scss";

.customaccordion {
    background-color: transparent;
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    color: var(--themecolor);
}
