@import "../../../../global.scss";

.filecontainer {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-direction: column;
    margin: 5px 10px 5px 0;
}
.materialelementcontainer.link {
    flex-direction: column;
    min-height: 75px;
}

.link {
    padding-top: 10px;
}
.materialname {
    a {
        display: flex;
    }
    span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    svg {
        min-width: initial;
        overflow: visible;
    }
}
.materialdownload {
    display: flex;
    width: 15%;
    height: 25px;
    justify-content: center;
}

.materialtext {
    display: flex;
    padding-top: 4px;
    font-size: small;
}

.linkcontainer {
    margin-right: 20px;
    a {
        text-decoration: underline;
    }
}
