@import "../../global.scss";

.sign_up_form {
    width: 100%;
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
}

.infoboxcontainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}

.infobox {
    width: 260px;
    background-color: var(--form_bgcolor);
    padding-bottom: 25px;
    margin-bottom: 25px;
}

.infoboxcontent {
    padding: 0 25px;
}

.infoboxlabel {
    font-weight: 600;
    padding: 10px 0 3px;
}
.longdescription {
    font-size: 18px !important;
    line-height: 26px;
    margin: 24px 0px;
}
.shortdescription {
    font-size: 20px;
    line-height: 28px;
    margin: 24px 0 46px;
}

.divtitle,
.sessiontitle {
    font-size: 20px;
    line-height: 26px;
    font-weight: 800;
    margin-top: 25px;
}
.detailwindow {
    background-color: #ffffff;
    height: 100%;
    overflow:hidden;

    img {
        width: 100%;
        max-height: 390px;
    }

    .detailinfo {
        padding-left: 38px;
    }

    .indetailmain {
        background-color: var(--form_bgcolor);
    }

    .title {
        font-weight: 800;
        font-size: 22px;
        line-height: 27px;
        margin: 22px 0;
    }

    .description {
        font-size: 16px !important;
        line-height: 26px;
        margin-top: 24px;
    }

    .gobacklink {
        text-decoration: underline;
        cursor: pointer;
        color: var(--darkturquoise);
        margin-top: 34px;
    }
}
.buttoncontainer {
    display: flex;
    margin-top: 25px;
    width: 100%;
    max-width: 550px;
}
.statusbutton {
    display: none;
}
.eventcancelbtn {
    margin-bottom: 5px !important;
    margin-top:5px;
}
.signedup,
.awaiting,
.ended {
    height: 51px;
    color: #ffffff;
    display: flex;
    align-items: center;
    padding: 0 15px;
}
.awaiting {
    background-color: var(--orange);
    svg {
        margin-right: 10px;
        path {
            fill: #ffffff;
            stroke: #ffffff;
        }
    }
}
.signedup {
    background-color: var(--green);
    svg {
        margin-right: 10px;
        path {
           
            stroke: #ffffff;
        }
    }
}
.ended {
    background-color: var(--orange);
 
}

.mainleft,
.mainright {
    background-color: var(--darkbeige);
    flex-grow: 1;
}

.mainleft {
    margin-left: $container-margin;
    padding-left: $container-padding;
}

.icon {
    width: 16px;
    display: flex;
    margin-right: 10px;
}

.date,
.place {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 12px;
    display: flex;
}

.mainright {
    img {
        width: 100%;
        max-width: 550px;
    }

    display: flex !important;
    justify-content: flex-end;
}
.registerbutton {
    margin: 15px 0 25px;
}
@media (max-width: $breakpoint-xlarge) {
    .mainright,
    .statusbutton {
        margin-left: $container-margin;
        padding: 0 $container-padding;
        align-items: center;
        justify-content: center;
    }
    .statusbutton {
        background-color: var(--darkbeige);
        display: flex;
        flex-direction: column;
        padding: 5px 0;
        margin-bottom: 5px;
    }
    .buttoncontainer {
        display: none !important;
    }
}

@media (max-width: $breakpoint-med) {
    .mainright,
    .statusbutton {
        margin: 0 $container-margin-med;
        padding: 25px $container-padding-med;
    }
    .mainleft {
        margin: 0 $container-margin-med;
        padding-left: $container-padding-med;
    }
}

.hidden {
    display: none;
}
.webcontent,
.deadlines,
.sessioncontainer {
    padding: 5px;
    margin-bottom: 25px;
}

.materialcontainer {
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 200px;
}

.deadlines {
    padding-bottom: 25px;
}

.eventdetails {
    height: 100%;
}
.infomation{
    padding:20px;
}
.buttoncontainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    button {
        margin: 5px 0 0 5px;
    }
}

.infomessagecontainer {
    border: var(--themecolor) 1px solid;
    padding: 10px;
    background-color: var(--form_bgcolor);
    margin-top: 15px;
}

.deadlinedate {
    padding-left: 20px;
    display: inline-flex;
}

.deadlinelabel {
    display: inline-flex;
}

.sessios {
    justify-content: space-around;
    flex-direction: row;
    display: flex;

    .infobox,
    .sessionmaterials > div {
        background-color: var(--form_bgcolor_opac);
    }
}

.sessionmaterials {
    padding-left: 4px;
    width: 80%;
    height: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.zexpense {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
}

.sessionstatus {
    flex-direction: column;

    button {
        float: right;
    }
}
