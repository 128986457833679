@import "../../../global.scss";
.submitbtn{
   margin-top: 15px;
   justify-content: flex-end;
   display:flex;
   button{
      background-color: $pink;
   }
}
.formitemcontainer{
   margin:0 15px;
   display: flex;
   flex-direction: column;
}
.msgbox{
   align-self: flex-end;
}
.successtext{
   color:green;
 }
 .formitem{
   width:100%;
   max-width: 640px;
   margin:25px auto;  
}

.fielditembox{
   display: flex;
   flex-direction: column;

}
.fielditemboxWrapper{
   display: flex;
   flex-direction: row;

}
.caption{
   font-size:16px;
   font-weight: 700;
}
.fieldsets{
   margin-bottom: 15px;
}

.attachment{
   padding:15px 0 15px
}