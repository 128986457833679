@import "../../../global.scss";

.toptitle_text {
    display: flex;
    font-weight: 800;
    font-size: 22px;
    line-height: 26px;
    padding-left: 10px;
}
.text {
    display: flex;
    min-width: 285px;
}
.toptitle_container {
    margin-bottom: 20px;
    margin-top: 20px;
}
.haslabel {
    margin-bottom: 0px;
}
.withoutcorner {
    padding-left: 0px;
}
.cornercontainer {
    position: relative;
}
.iconcontainer {
    margin-left: 20px;
    position: relative;
    display: flex;
}
.toptitlecontainer {
    position: relative;
}
@media (max-width: $breakpoint-med) {
    .iconcontainer {
        flex-grow: 1;
        justify-content: flex-end;
    }
}
