@import "../../global.scss";

.situationlist {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  td,
  th {
    padding-left: 10px;
    color: var(--themecolor);
  }
  .divider {
    box-shadow: 0 4px 4px -4px var(--form_box_color);
  }
  .bold {
    font-weight: bold;
  }
  table {
    border-collapse: collapse;
    width: 100%;
  }
  th {
    text-align: left;
    font-weight: 550;
  }
  td {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .showbuttoncolumn {
    padding-right: 10px;
    text-align: right;
  }

  .header {
    background-color: var(--form_bgcolor);
  }
}
.closebutton,
.createnewbutton {
  justify-content: flex-end;
  display: flex;
}
.createnewbutton {
  flex-direction: column;
}
.closebutton {
  margin-top: 15px;
}
.createnewbutton button {
  align-self: flex-end;
}
.readonlylist {
  padding-top: 50px;
}
.disabledmessagecontainer {
  border: var(--themecolor) 1px solid;
  padding: 10px;
  background-color: var(--form_bgcolor);
  margin-bottom: 15px;
}
.readonlyeducationlist {
  padding: 5px 0;
  min-height: 24px;
  background-color: var(--form_disabled_bgcolor);
  ul {
    padding-left: 20px;
    list-style: none;
  }
  ul li::before {
    content: "\2022";
    color: var(--themecolor);
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
}
