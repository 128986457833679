@import "../../global.scss";
.eventfilter {
  margin-right: 25px;
}

.eventcard {
  margin-bottom: 15px;
}
.newevents {
  display: flex;
  flex-direction: row;
  position: relative;
}
.eventcardlist {
  width: 100%;
}

.newevents {
  height: 100%;
}
.hidden {
  visibility: hidden;
}
.eventcarouselcontainer {
  margin-bottom: 0px;
}
@media (max-width: 840px) {
  .eventfilter {
    position: absolute;
    z-index: 2;
  }
  .filtericon {
    display: flex;
    width: 100%;
    text-align: left;
    margin: 10px 0 5px;
    cursor: pointer;
  }
  .filtericon > div {
    width: 14px;
    height: 14px;
  }
}

