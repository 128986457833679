@import "../../../global.scss";

.name {
    svg {
        &:nth-child(2) {
            path {
                fill: var(--ctabuttoncolor);
            }
            margin-right: 5px;
        }
        &:first-child {
            path {
                stroke: var(--ctabuttoncolor);
            }
        }
    }

    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    .date {
        flex-grow: 1;
        display: none;
        justify-content: flex-end;
    }
}
.outbound {
    svg:first-child {
        transform: rotate(180deg);
    }
}
.documents {
    margin-top: 15px;
}
.messagetitle {
    font-weight: 800;
    line-height: 26px;
    font-size: 22px;
    margin-top: 15px;
    &:hover {
        cursor: pointer;
        color: var(--overview_hover);
    }
}
.opencontent {
    display: block;
    height: auto;
    text-overflow: initial;
    overflow: initial;
}
.closedcontent {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
}
.content,
.content * {
    font-size: 16px !important;
    line-height: 22px !important;
}
.content {
    width: 100%;
    padding-top: 10px;
    padding-right: 5px;
}
