@import "../../global.scss";

.campaigncontainer {
    display: flex;
    align-items: center;
    padding-top: 2px;
    padding-bottom: 15px;
}
.campaign {
    width: 100%;
    height: 375px;
    background-position: right;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.background {
    width: 100%;
    height: 100%;
}
.watermark {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat no-repeat;
    background-position: 115% center;
    background-size: 50%;
    filter: brightness(0) invert(1) brightness(80%); // Make svg white
    opacity: 0.2; // Make svg take the appearance of the background color
}
.image {
    display: flex;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat no-repeat;
    background-position: center;
}
.textbox {
    width: 60%;
    height: 70%;
    position: absolute;
    background-color: white;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
}
.title {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 22px;
    width: 80%;
    height: 30%;
    font-weight: 500;
    overflow-wrap: break-word;
}
.text {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
    width: 80%;
    height: 60%;
    font-size: medium;
}
.button {
    position: absolute;
    bottom: 25px;
}

@media (max-width: 480px) {
    .campaign {
        width: 100%;
        height: 400px;
        background-position: right;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .watermark {
        
        background-repeat: no-repeat no-repeat;
        background-position: -225% center;
        background-size: 110%;
        filter: brightness(0) invert(1) brightness(80%); // Make svg white
        opacity: 0.2; // Make svg take the appearance of the background color
    }

    .textbox {
        width: 85%;
        height: 70%;
        position: absolute;
        background-color: white;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
    }
}
