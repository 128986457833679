@import "./variables.scss";
.grecaptcha-badge {
  visibility: hidden;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.elementcontainer {
  padding: 15px;
}
.input,
.textarea,
.dropdown {
  &:disabled {
    outline: 0;
    border: 0;
    background-color: transparent;
    height: 22px;
  }
}
.error {
  border-color: red !important;
}
.errortext {
  color: red;
}
.errorMessage {
  display: flex;
  color: red;
  font-size: 12px;
}
.inputinformationtxt {
  display: flex;
  color: var(--inputbordercolor);
  font-size: 12px;
  padding-left: 3px;
}
.step {
  color: var(--themecolor);
}
.stepTitle {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: var(--themecolor);
  text-align: left;
  padding: 38px 0;
}
.stepLabel {
  font-size: 16px;
  margin: 15px 0 3px;
}
.stepMiniLabel {
  font-size: 12px;
  color: #7f7f7f;
}
.stepLabelWithMiniLabel {
  display: flex;
  flex-direction: row;
  .stepMiniLabel {
    margin-bottom: 3px;
    margin-left: 15px;
    align-self: flex-end;
  }
}
.requiredstar {
  font-size: 16px;
  padding-left: 3px;
  font-weight: 700;
}
.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 15px;
}
.next {
  display: flex;
  padding: 0 40px 0px;

  .canceldiv button {
    background-color: var(--themecolor);
  }
}
.prev {
  display: flex;
  padding: 0 40px 0px;
}

.wrappeddetail.wrappedhidden {
  display: none;
}
.wrappedmain .wrappedshow {
  display: flex;
}
ul {
  margin: 0;
  padding: 0;
}
.elementcontent {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 90%;
  margin: auto;
  max-width: 840px;
}
.formInformation {
  max-width: 443px;
}
/* overriding datepicker styling */

.react-date-picker,
.react-date-picker__wrapper {
  width: 100%;
  border: 0 !important;
  max-width: 443px;
}
.has_error .react-date-picker__wrapper {
  border-color: var(--red) !important;
}
.react-date-picker__wrapper {
  border-radius: 6px;
  border: 1px solid var(--inputbordercolor) !important;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  height: 54px;
  background-color: var(--white);
}
.react-date-picker__calendar {
  width: 100%;
  min-width: 300px;
  min-height: 300px;
}

.react-date-picker__inputGroup__input:focus,
.react-date-picker__button:focus {
  border: 0;
  outline: 0;
}
.react-date-picker__button:hover,
.react-date-picker__button,
.react-date-picker__button:hover {
  color: var(--themecolor);
}
.no_calender_divider span.react-date-picker__inputGroup__divider,
.no_calender_divider .react-date-picker__clear-button {
  display: none;
}
.react-date-picker__inputGroup__input:invalid {
  background-color: var(--white) !important;
}
.react-calendar__tile--now {
  background-color: $form_bgcolor !important;
}
.react-date-picker__button {
  padding-right: 0;
}
.react-date-picker__calendar-button.react-date-picker__button {
  svg {
    width: 15px;
    height: 15px;
  }
}
.react-calendar__tile--active {
  background-color: $pink !important;
  color: #ffffff;
}

.react-calendar__tile:disabled {
  background-color: var(--form_disabled_bgcolor) !important;
}
.react-calendar__month-view__weekdays {
  background-color: $form_bgcolor;
  abbr {
    text-decoration: none;
  }
}
.react-calendar__month-view__weekdays__weekday {
  border-right: white 1px solid;
}
.react-calendar {
  border-color: $form_bgcolor !important;
  width: 100%;
  min-height: 300px;
}

/** multiselect */
.dlfms__control {
  border-radius: 6px;
  border: 1px solid var(--inputbordercolor) !important;
  height: auto !important;
  min-height: 54px !important;
  width: 100%;
  max-width: 443px;
  padding-left: 6px;
  padding-right: 16px;
  * {
    color: var(--themecolor);
    font-family: Rubik, Arial, Helvetica, sans-serif !important;
  }
}

.dlfms__control--is-focused {
  box-shadow: none !important;
  outline: none;
  border: 1px solid var(--themecolor) !important;
}

.dlfms__indicator {
  padding: 0px !important;
  color: black !important;
}

.dlfms__indicator-separator {
  width: 0 !important;
}

.dlfms__menu {
    border-radius: 0 !important;
    //  background-color: var(--inputbordercolor) !important;
    margin: 0 !important;
    max-width: 443px;
}

// .dlfms__menu-list {
// }

.dlfms__option {
  // border-bottom: 1px solid white;
  //  font-size: 14px !important;
  word-break: break-word;
  padding: 1px 6px !important;
  // font-family: Rubik, Arial, Helvetica, sans-serif !important;
}

.dlfms__option--is-focused {
  // background-color: var(--form_bgcolor__hover) !important;
  font-weight: 700;
  cursor: pointer;
}

.dlfms__value-container {
  padding: 0 6px !important;
  // font-family: Rubik, Arial, Helvetica, sans-serif !important;
}
.input,
.textarea,
.dropdown_header,
.dropdown_option_list {
  border: 1px solid var(--inputbordercolor);
  width: 100%;
  max-width: 443px;
  box-sizing: border-box;
  padding-left: 16px;
  border-radius: 6px;
  font-size: 18px;
  &:focus {
    border: 1px solid var(--themecolor);
  }
  &:focus-visible {
    outline: none;
  }
}
.input,
.dropdown_header {
  height: 54px;
}
.textarea {
  min-height: 54px;
  color: var(--themecolor);
  padding: 16px;
}

/** dropdown*/
.dropdown {
  position: relative;
  padding-left: 0;
  &:focus {
    border-color: transparent;
  }
  * {
    color: var(--themecolor);
  }
  select,
  .dropdown_header,
  .dropdown_option_item {
    background-color: #ffffff;
    height: 54px;
    display: flex;
    width: 100%;
    position: relative;
    align-items: center;
    svg {
      display: flex;
      width: 16px;
      height: 16px;
      align-self: center;
      margin: 0 10px;
    }
    &:hover {
      cursor: pointer;
    }
    &:focus {
      outline-width: 0;
    }
  }
  .dropdown_option_list {
    background-color: #ffffff;
    position: absolute;
    width: 100%;
    z-index: 100;
    padding: 3px;
    max-height: 250px;
    overflow: auto;
  }
  .dropdwon_header_title,
  .dropdown_option_item,
  .dropdwon_header_title_placeholder {
    text-align: left;
    width: 100%;
    display: flex;
    align-self: center;
  }

  .dropdown_option_item {
    border: none;
    font-size: 16px;
    &:hover {
      font-weight: 700;
      cursor: pointer;
    }
  }
  .selected {
    font-weight: 700;
    cursor: pointer;
  }

  .dropdown_addnew_text {
    color: var(--logocolor);
    svg {
      margin-left: 3px;
      right: auto;
      path {
        fill: var(--logocolor);
      }
    }
  }
  .dropdown_addnew_btn {
    border: 0;
    height: 100%;
    background-color: $pink;
    color: white;
    position: relative;
    span {
      color: white;
      white-space: nowrap;
    }
    &:focus {
      outline: 0;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .dropdown_addnew_inputcontainer {
    display: flex;
    padding: 5px;
    border: $form_bgcolor 1px solid;
  }
  .dropdown_addnew_inputcontainer > div {
    margin-right: 5px;
  }
}

/**wizard - used also in forms*/
.wizard,
.wizardform {
  width: 100%;
}

.wizardinfo,
.wizardmain {
  height: auto;
  min-height: 100%;
}

.wizardmain,
.wizard {
  display: flex;
}
.wizard {
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
}
.wizardform {
  padding-right: 0;
  padding-bottom: 65px;
}
.flowbuttons {
  display: flex;
  justify-content: space-between;
}
@media (max-width: $breakpoint-med) {
  .flowbuttons {
    position: fixed;
    margin: 10px 0 0;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 999;
  }
  .wrappedmain.eventcardmain {
    padding-left: 0 !important;
  }
}
.wrappedmain.eventcardmain {
  padding-left: 25px;
  .eventctitlardtitle {
    overflow: visible;
    white-space: unset;
  }
}
.wrappeddetail.eventcarddetail {
  margin-top: 20px;
  padding-top: 16px;
  border-top: 1px var(--bordercolor) solid;
}

//editor
.rdw-editor-main {
  padding: 0 10px;
  min-height: 220px;
  max-height: 220px;
}

.DraftEditor-root {
  min-height: 200px;
  max-height: 200px;
}
.DraftEditor-editorContainer,
.public-DraftEditor-content {
  min-height: 200px;
}
//grid - inspiration from (https://simplegrid.io/)

.row {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  width: $width;
}

@media (max-width: $breakpoint-small) {
  .col-1-sm {
    width: ($width / 12) - ($gutter * 11 / 12);
  }
  .col-2-sm {
    width: ($width / 6) - ($gutter * 10 / 12);
  }
  .col-3-sm {
    width: ($width / 4) - ($gutter * 9 / 12);
  }
  .col-4-sm {
    width: ($width / 3) - ($gutter * 8 / 12);
  }
  .col-5-sm {
    width: ($width / (12 / 5)) - ($gutter * 7 / 12);
  }
  .col-6-sm {
    width: ($width / 2) - ($gutter * 6 / 12);
  }
  .col-7-sm {
    width: ($width / (12 / 7)) - ($gutter * 5 / 12);
  }
  .col-8-sm {
    width: ($width / (12 / 8)) - ($gutter * 4 / 12);
  }
  .col-9-sm {
    width: ($width / (12 / 9)) - ($gutter * 3 / 12);
  }
  .col-10-sm {
    width: ($width / (12 / 10)) - ($gutter * 2 / 12);
  }
  .col-11-sm {
    width: ($width / (12 / 11)) - ($gutter * 1 / 12);
  }
  .col-12-sm {
    width: $width;
  }

  .hiddensm {
    display: none;
  }
}
.showmd,
.showlg {
  display: none;
}
@media (max-width: $breakpoint-med) {
  .hiddenmd {
    display: none;
  }
  .showmd {
    display: initial;
  }
}
@media (max-width: $breakpoint-large) {
  .hiddenlg {
    display: none;
  }
  .showlg {
    display: initial;
  }
}
@media (min-width: $breakpoint-med) {
  .col-1 {
    width: ($width / 12) - ($gutter * 11 / 12);
  }
  .col-2 {
    width: ($width / 6) - ($gutter * 10 / 12);
  }
  .col-3 {
    width: ($width / 4) - ($gutter * 9 / 12);
  }
  .col-4 {
    width: ($width / 3) - ($gutter * 8 / 12);
  }
  .col-5 {
    width: ($width / (12 / 5)) - ($gutter * 7 / 12);
  }
  .col-6 {
    width: ($width / 2) - ($gutter * 6 / 12);
  }
  .col-7 {
    width: ($width / (12 / 7)) - ($gutter * 5 / 12);
  }
  .col-8 {
    width: ($width / (12 / 8)) - ($gutter * 4 / 12);
  }
  .col-9 {
    width: ($width / (12 / 9)) - ($gutter * 3 / 12);
  }
  .col-10 {
    width: ($width / (12 / 10)) - ($gutter * 2 / 12);
  }
  .col-11 {
    width: ($width / (12 / 11)) - ($gutter * 1 / 12);
  }
  .col-12 {
    width: $width;
  }
}

@media (max-width: $breakpoint-large) and (min-width: $breakpoint-med) {
  .col-1-md {
    width: ($width / 12) - ($gutter * 11 / 12);
  }
  .col-2-md {
    width: ($width / 6) - ($gutter * 10 / 12);
  }
  .col-3-md {
    width: ($width / 4) - ($gutter * 9 / 12);
  }
  .col-4-md {
    width: ($width / 3) - ($gutter * 8 / 12);
  }
  .col-5-md {
    width: ($width / (12 / 5)) - ($gutter * 7 / 12);
  }
  .col-6-md {
    width: ($width / 2) - ($gutter * 6 / 12);
  }
  .col-7-md {
    width: ($width / (12 / 7)) - ($gutter * 5 / 12);
  }
  .col-8-md {
    width: ($width / (12 / 8)) - ($gutter * 4 / 12);
  }
  .col-9-md {
    width: ($width / (12 / 9)) - ($gutter * 3 / 12);
  }
  .col-10-md {
    width: ($width / (12 / 10)) - ($gutter * 2 / 12);
  }
  .col-11-md {
    width: ($width / (12 / 11)) - ($gutter * 1 / 12);
  }
  .col-12-md {
    width: $width;
  }
}

@media (max-width: $breakpoint-xlarge) and (min-width: $breakpoint-large) {
  .col-1-lg {
    width: ($width / 12) - ($gutter * 11 / 12);
  }
  .col-2-lg {
    width: ($width / 6) - ($gutter * 10 / 12);
  }
  .col-3-lg {
    width: ($width / 4) - ($gutter * 9 / 12);
  }
  .col-4-lg {
    width: ($width / 3) - ($gutter * 8 / 12);
  }
  .col-5-lg {
    width: ($width / (12 / 5)) - ($gutter * 7 / 12);
  }
  .col-6-lg {
    width: ($width / 2) - ($gutter * 6 / 12);
  }
  .col-7-lg {
    width: ($width / (12 / 7)) - ($gutter * 5 / 12);
  }
  .col-8-lg {
    width: ($width / (12 / 8)) - ($gutter * 4 / 12);
  }
  .col-9-lg {
    width: ($width / (12 / 9)) - ($gutter * 3 / 12);
  }
  .col-10-lg {
    width: ($width / (12 / 10)) - ($gutter * 2 / 12);
  }
  .col-11-lg {
    width: ($width / (12 / 11)) - ($gutter * 1 / 12);
  }
  .col-12-lg {
    width: $width;
  }
}
