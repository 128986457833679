@import "../../../global.scss";

.billingdetails {
    font-size: 16px;
    tbody tr:first-child td {
        padding-top: 15px;
    }
    table tbody tr:last-child td:last-child {
        font-weight: 800;
    }
    table tbody tr td:last-child,
    table thead tr th:last-child {
        text-align: right;
        padding-right: 15px;
    }
    table tbody tr td:first-child,
    table thead tr th:first-child {
        padding-left: 15px;
        font-weight: 800;
    }
    td {
        padding-top: 5px;
        padding-bottom: 5px;
    }
    table thead tr {
        th:first-child {
            font-size: 20px !important;
            line-height: 27px;
        }
        th:last-child {
            font-weight: 800;
        }
        height: 56px;
    }
}
