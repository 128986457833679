@import "../../../global.scss";

.fileattachtment {
  display: flex;
  max-width: 443px;

  input {
    visibility: hidden;
    height: 0;
    width: 0;
    display: flex;
  }

  .filename {
    margin-left: 5px;
    align-self: center;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
    flex: auto;
    font-size: 16px;
  }
}

.customfileupload {
  display: flex;
  width: 100%;
  button {
    min-width: 80px;
    width: 108px;
    flex-shrink: 0;
  }
  .cancelicon {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}
.uploadbtngroup{
  flex-direction: column;
  .inputinformationtxt{
    padding-left:5px
  }
}