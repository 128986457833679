@import "../../../global.scss";

.signedUpfilter {
    display: flex;
    width: 100%;
}

.signedUpfilter.false {
    visibility: hidden;
}
.materialtitle {
    &:first-child {
        margin-top: 0;
    }
    margin-top: 25px;
}

.materialSignedUpText {
    display: flex;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    color: var(--themecolor);
}

.materialSignedUpText > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.onlinematerial {
    margin-right: 20px;
}

.nomaterial {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.materialelements {
    width: 100%;
}

.materials {
    width: 100%;
    flex-direction: column;
    flex-wrap: wrap;
    display: flex;
    div,
    button {
        max-width: 100%;
    }
}
// If not signed up cant copy text
.materials.false {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
