@import "../../../global.scss";

.custominput {
  width: 100%;
  background-color: transparent;
  outline: 0;
  border: 0;
  position: relative;
}
.cprnrcontainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.doublecheckbox {
  margin-left: 10px;
}
@media (max-width: 400px) {
  .cprnrcontainer {
    flex-wrap: wrap;
  }
  .doublecheckbox {
    margin-left: 0;
  }
}
