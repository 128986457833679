@import "../../global.scss";

.eventcard {
  margin-bottom: 15px;
}
.newevents {
  display: flex;
  flex-direction: row;
  position: relative;
}
.eventcardlist {
  width: 100%;
  .collapsibleinfo,.collapsibleinfo > div {
    margin-bottom: 10px;
  }
}

.newevents {
  height: 100%;
}
.hidden {
  visibility: hidden;
}
@media (max-width: 840px) {

  .filtericon {
    display: flex;
    width: 100%;
    text-align: left;
    margin: 10px 0 5px;
    cursor: pointer;
  }
  .filtericon > div {
    width: 14px;
    height: 14px;
  }
}
