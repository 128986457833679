@import "../../../global.scss";
.eventcard {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 120px;
  background-color: #ffffff;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.102));
}
.main {
  padding-left: 10px;
}
.detail,
.main {
  height: 100%;
  display: flex;
  flex-direction: column;

  .readmore {
    text-decoration: underline;
    display: flex;
    font-size: 16px;
    line-height: 18.96px;
    font-weight: 500;
    &:hover {
      cursor: pointer;
      color: var(--overview_hover);
    }
  }
  .deadline{
    color: var(--orange);
    font-weight: 700;
  }
  .place,
  .detailline {
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    display: flex;
    align-items: center;
    svg {
      margin-right: 13px;
      min-width: 14px;
    }
  }
  .place > div:first-child {
    display: flex;
    position: relative;
    width: 100%;
    flex-grow: 1;
  }
  .detailline {
    padding: 16px 0 16px 10px;
  }
  .title,
  .description {
    height: 100%;
    display: flex;
    color: var(--themecolor);
  }
  .title {
    font-size: 22px;
    line-height: 26px;
    max-width: 50ch;
    margin-bottom: 5px;
    margin-top: 16px;

    height: auto;

    font-weight: 800;
    &:hover {
      cursor: pointer;
      color: var(--overview_hover);
    }
  }
  .description {
    height: fit-content;
    max-width: 160ch;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
}
.descriptioncontainer {
  flex-grow: 1;
  padding-right: 10px;
}
.awaiting,
.ended {
  color: var(--orange);
}
.signedup {
  color: var(--green);
}
.signedup,
.awaiting,
.ended {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  svg {
    width: 24px;
    height: 24px;
    margin-right: 13px;
  }
}
.status {
  padding: 0 0 20px;
  border-bottom: 1px var(--bordercolor) solid;
  &:first-child {
    padding-left: 10px;
  }
}
.eventimg {
  width: 120px;
  height: 118px;
  position: relative;
  display: flex;

  img {
    min-width: 100px;
    max-width: 100px;
    height: fit-content;
    margin: auto;
  }
}

.date {
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  font-size: 10px;
  color: rgba(112, 112, 112, 1);
  align-items: flex-end;
  background-image: linear-gradient(transparent, white);
  white-space: nowrap;
  padding-left: 3px;
}

.buttoncontainer > button {
  height: 27px;
}
.buttoncontainer {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 5px;
  padding-top: 10px;
}
.minidate {
  display: none;
  color: var(--themecolor);
  font-size: 10px;
}

@media (max-width: 480px) {
  .main {
    .description {
      -webkit-line-clamp: 2;
    }
  }
  .minidate {
    display: block;
  }
  .eventimg {
    display: none;
  }
  .buttoncontainer {
    flex-wrap: wrap;
    justify-content: center;
    button {
      width: 80%;
      margin-bottom: 5px;
    }
  }
}
