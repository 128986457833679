@import "../../../global.scss";

.yesnocontainer,.group{
  display: flex;
}
.group{
  padding-right:20px;
}
.yesnocontainer{
  margin:10px 0 25px;
  flex-wrap: wrap;
}
