@import "../../global.scss";

.changepaymentcontainer {
    display: flex;
    padding-top: 2px;
    padding-bottom: 15px;
    flex-direction: column;
}
.title {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 22px;
    width: 80%;
    height: 30%;
    font-weight: 500;
    overflow-wrap: break-word;
}
.textcontainer {
    display: flex;
    align-items: flex-start;
    text-align: center;
    font-size: medium;
    border-bottom: 1px var(--inputbordercolor) solid;
    margin-bottom: 11px;
    flex-direction: column;
}
.text{
    display: flex;
    margin-bottom: 11px;
    font-size: 16px;
    font-weight: 700;
    /*width: 60%;*/
}
.paymentmethod{
    display: flex;
}
.button {
}
