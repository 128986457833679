@import "../../global.scss";

.situationchange {
  width: 100%;
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
}
.situationchangecontainer {
  padding: 40px;
}
.datepickercontainer {
  display: flex;
  flex-direction: column;
}
.dategroup {
  display: flex;
  flex-direction: column;
  min-width: 250px;
  margin-bottom: 15px;
}
.samesituationmsg {
  padding: 15px;
}
.changesituationcontent,
.datepickercontainer {
  margin: 25px 0;
}
.onlytitle {
  padding: 15px;
}
.changesituationmsg {
  border: var(--themecolor) 1px solid;
  padding: 10px;
  background-color: var(--form_bgcolor);
  margin-top: 15px;
}
.onedatebox {
  width: 100%;
}


@media (max-width: 860px) {
  .datepickercontainer {
    flex-wrap: wrap;
  }
  .dategroup {
    min-width: 100%;
  }
}