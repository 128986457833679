@import "../../../global.scss";

.checkbox {
  position: relative;
  height: 24px;
  width: 24px;
  min-width: 24px;
  max-width:24px;
  margin:inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border-radius: 3px;
  outline: none;
  cursor: pointer;    
  border: 1px solid var(--inputbordercolor);
  
  background-color: var(--themefontcolor);
  &.error {
    border: red solid 1px;
  }
  &:checked {
   background-color: var(--themecolor);
  }
}
.checkboxgroup {
  position: relative;
  display: flex;
}
.clickactivated{
  cursor: pointer;
}
.checkboxlabel {
  display: flex;
  align-items: center;
  margin-left: 10px;
  p {
    margin: 0;
  }
}
.checkspan {
  cursor: pointer;
  position: absolute;
  display: flex;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
}
