@import "../../../../global.scss";
.spinnercircle,
.quatercircle {
  position: absolute;
  top: 0;  
  margin:0!important;
}
.spinnercircle {
  left: 0;
}
.quatercircle {
  left: 50%;
}
.spinnerdiv {
  position: relative;
  animation: spin 2s linear infinite;
}
