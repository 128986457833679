
.dlf_corner_inner {
    fill: transparent;
    stroke: var(--themecolor);
    stroke-width: 3px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.dlf_corner_outer {
    overflow: visible;
    position: absolute;
    width: 16px;
    height: 16px;
    left: 2px;
    top: -2px;
    transform: matrix(1,0,0,1,0,0);
}