@import "../../../global.scss";
.buttonscontainer{
    margin-top: 15px;
}
.cancelbuttoncontainer {
    display:flex;
    width:50%;
}
.okbuttoncontainer {
    display: flex;
    width: 50%;
    justify-content: end;
}