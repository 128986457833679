@import "../../../global.scss";

.deliverychangeborder {
    display: flex;
    width: 100%;
    color: var(--themecolor);
    flex-direction: column;
    padding-bottom: 15px;
}
.deliverychangecontainer {
    display: flex;
    flex-flow: column;
}
.subscriptiontitles {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid var(--themecolor);
    padding-top: 2px;
    padding-bottom: 2px;
    text-align: left;
    font-weight: 550;

    .subscriptionname {
        width: 65%;
        padding-left: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .subscriptionformat {
        display: flex;
        width: 35%;
        justify-content: flex-end;
        padding-right: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .defaultsubscriptionname {
        width: 30%;
        padding-left: 15px;
    }

    .defaultsubscriptionformat {
        display: flex;
        justify-content: flex-end;
        width: 70%;
        padding-right: 15px;
    }
}
.deliverychangelist {
    display: flex;
    flex-flow: column;
    width: 100%;
}

.deliverychangebutton > button {
    width: 75px;
    float: right;
    margin-top: 15px;
}

.nomessages {
    padding: 5px 0px 5px 15px;
}

@media (max-width: 840px) {
    .subscriptiontitles {
        flex-direction: column;

        .defaultsubscriptionname,
        .subscriptionname {
            visibility: hidden;
        }

        .defaultsubscriptionformat,
        .subscriptionformat {
            padding-left: 15px;
            margin-top: -20px;
            width: 100%;
            justify-content: start;
        }
    }
}
