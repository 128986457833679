@import "../../../global.scss";

.statuscontainer {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.statustitle {
    padding: 0 0 20px;
    border-bottom: 1px var(--bordercolor) solid;

    &:first-child {
        padding-left: 10px;
    }
}
.activeperiod {
    display: flex;
    padding: 16px 0 16px 10px;
    width:100%;
    height:100%;
}
.activeperiod {
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    align-items: center;

    svg {
        margin-right: 13px;
        min-width: 14px;
    }
}

.groupcontainer {
    display: flex;
    padding: 16px 0 16px 10px;
    width: 100%;
    height: 100%;

    svg {
        margin-right: 13px;
        min-width: 14px;
    }
}
.between {
    font-size: medium;
    text-align: center;
}

.insurancebox {
    margin-bottom: 15px;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.102));
}

.buttoncontainer {
    padding-top: 15px;
    display: flex;
    align-self: flex-end;
    justify-content: center;    
}

.nocoInsured {
    color: $pink;
}

.status,
.group,
.nocoInsured {
    padding-bottom: 5px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    align-items: center;
}

.rejected {
    color: var(--orange);
}

.signedup {
    color: var(--green);
}

