@import "../../global.scss";

.messagesentcontainer {
 
    padding: 15px ;
}

.substitle {
    font-size: large;
}

.text {
    padding-top: 24px;
}
