@import "../../global.scss";

.hidden {
    visibility: hidden;
}
.textfieldtop {
    display: flex;
    flex-direction: column;
    padding: 15px 0;
}

.textfieldbot {
    height: 75%;
    display: flex;
    flex-direction: column;
    padding: 15px 0;
}

.fileattachtment {
    padding-bottom: 5px;
}
.hiddenblurdiv {
    height: 0px;
    width: 0px;
    background-color: transparent;
    border: none;
    padding: 0;
    &:focus,
    &:active,
    &:focus-visible {
        border: none !important;
        padding: 0;
    }
}
.textfieldbot .editor {
    min-height: 225px;
    overflow: auto;
    border: 1px solid var(--inputbordercolor);
   
}
.erroreditor{
    border-color: red !important
}
.toolongmsg {
    margin-bottom: 25px;
    max-width: 443px;
    color:red !important;
}
.editor {
    background-color: #ffffff;
    border-radius: 6px;
    max-width: 443px;
    &:focus-within {
        border: 1px solid var(--themecolor);
    }
}
