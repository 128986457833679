.searchicon_outer {
  overflow: visible;
  position: absolute;
  width: 11.9px;
  height: 11.9px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.searchicon_inner {
  fill: rgba(27, 44, 80, 1);
}
