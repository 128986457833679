@import "../../global.scss";

.hidden {
  visibility: hidden;
}
.messagebox{
    margin-bottom:3px;
    position:relative;
}
.date{
    font-size: 14px;
    line-height: 22px;
    text-align: right;
    padding-right: 15px;
}

.wrappeddetail{
    visibility:hidden
}

.messages {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.nomessages {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    color: var(--themecolor);
}
.pagenumbers {
    display: flex;
    justify-content: center;
    padding-top: 15px;
}
.pagescontainer {
    display: flex;
    flex-direction: row;
}
