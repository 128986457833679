@import "../../../variables.scss";
.backbar{
background-color:  var(--bgcolor) ;
width:100%;
position:fixed;
margin-top:-10px;
z-index: 3;
}
.canceldiv {
  text-decoration: underline;
  cursor: pointer;
  display: flex;
  height: 70px;
  padding: 5px 0;
  align-items: center;
}
.cancelicon {
  height: 20px;
  width: 20px;
  margin-right: 20px;
  align-self: center;

  path {
    stroke: var(--themecolor);
  }
}
.topborder {
  height: 1px;
  margin-left: -82px;
  border-top: 1px solid var(--darkbeige);
  width: calc(100% + 82px);
}
.filter{
  margin-bottom: 10px;
  position: absolute;
  top: -45px;
  right: 0;
  z-index: 2;
}

@media (max-width: $breakpoint-med) {
.backbar{
  
margin-top:-30px;
}
.filter {
  position: absolute;
  z-index: 2;
}
  .topborder {
    margin-left: -20px;
    width: calc(100% + 40px);
  }
}

