@import "../../../global.scss";

.hovershow {
    background-color: white;
    color: var(--themecolor);
    font-size: 16px;
    font-weight: 400;
    left: -100px;
    width: 366px;
    position: absolute;
    line-height: 24px;
    top: calc(100% + 4px);
    z-index: 1;
}
.hovershow::after {
    content: " ";
    position: absolute;
    bottom: 100%;
    left: 110px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent white transparent;
}
.hidden {
    visibility: hidden;
}
.iconcontainer {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.closetext {
    color: var(--orange);
    text-decoration: underline;
}
.close {
    margin: 15px 15px 0 0;
    display: flex;
    justify-content: flex-end;
    font-weight: 500;
    align-items: center;
    svg {
        stroke: var(--orange);
        margin-left: 7px;
    }
    &:hover {
        cursor: pointer;
    }
}
.helpcontent {
    display: flex;
    flex-direction: column;
}
.helptext {
    padding: 5px 30px 30px;
}
@media (min-width: $breakpoint-med) and (max-width: 790px) {
    .hovershow {
        left: calc(100% - 360px);
    }
    .hovershow::after {
        left: calc(100% - 25px);
    }
}
@media (min-width: 790px) and (max-width: $breakpoint-large) {
    .hovershow {
        left: calc(100% - 335px);
    }
    .hovershow::after {
        left: calc(100% - 50px);
    }
}
@media (max-width: $breakpoint-med) {
    .hovershow {
        left: calc(100% - 350px);
    }
    .hovershow::after {
        left: calc(100% - 35px);
    }
}
