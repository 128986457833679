@import "../../global.scss";
.myinfo {
   // background-color: #ffffff;
    position: relative;
}
.consentlabel{
  margin-top: 20px;
}
@media (max-width: 768px) {  
  .onebutton{
    &>div{ justify-content: center;}   
  }
  .right,.left{
      width:100%;
      padding:5px;
  }
}
.myinfocontainer{
    display:flex;
    flex-direction: row;
}
.addresscontainer{
    display:flex;
    flex-direction: row;
    margin-top: 20px;
}
.right,.left{
    min-width:50%;
    padding:15px;
}
.errormsg{
  color:red;
}
.success{
  color:var(--logocolor)
}
.error,.success{
  text-align:right;
}

.padding {
  padding-left: 30px;
}

.disabled {
  opacity: 30%;
}