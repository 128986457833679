@import "../../../global.scss";
.folded_infomration {
  background-color: white;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.102));
  margin-top: 0;
  padding: 5px 40px;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
.collapsibleinfo {
  margin-bottom: 15px;
}

.openbutton {
  color: var(--themecolor);
  font-weight: 700;
  position: relative;
  svg {
    right: auto;
    top: 55%;
  }
  .arrow {
    margin-left: 3px;
  }

  &:hover {
    div:nth-child(2) {
      color: var(--overview_hover);
    }
    .arrow svg > path {
      fill: var(--overview_hover);
    }
    cursor: pointer;
  }
}
ul {
  margin: initial;
  padding: initial;
}
