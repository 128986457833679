@import "../../../global.scss";

.title {
  font-weight: 700 !important;
}
.filtercontainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  z-index:1;
}
.savebtn{
  margin-top:20px;
  align-self: center;
}
.filternumber{
  color: var(--themefontcolor);
  border-radius: 50%;
  background-color:var(--orange) ;
  height: 24px;
  border: 0;
  width: 24px;
  position: relative;
  margin-left:7px;
  font-size:13px;
  line-height: 15px;
  font-weight:400;
  display: flex;
  justify-content: center;
  align-items: center;
}
.filter {
  font-size: 16px;
  line-height: 18.96px;
  display: flex;
  flex-direction: column;
  width: 348px;
  max-width: 100%;
  padding: 10px;
  background-color: var(--darkbeige);
  color: var(--themecolor);
  height: fit-content;
  padding-bottom: 20px;

  ul {
    width: 100%;
    padding: 0;
    margin: 0;
  }
  li {
    list-style: none;
  }
}
.filteritem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px;

  font-weight: 400;
  align-items: center;
  border-bottom: var(--themecolor) 1px solid;
}
.close {
  display: flex;
  align-self: flex-end;
  font-weight: 500;
  align-items: center;
  svg {
    stroke: red;
    margin-right:7px;
  }
  &:hover {
    cursor: pointer;
  }
}
