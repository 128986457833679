@import "../../../variables.scss";
.menuicon {
    height: 100%;
    display: flex;
    width: 20px;
    margin-right: 20px;
    svg {
        fill: var(--themecolor);
        align-self: center;
        height: 20px;
    }
}
.mobilemenu {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.menutext {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    padding: 0 10px;
    display: flex;
}
@media (max-width: $breakpoint-med) {
    .menuicon {
        margin-right: 0;
    }
}
