@import "../../../global.scss";
.address1container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.initialloader {
  display: flex;
  justify-content: center;
}
.alphabet,
.houseNo,
.floor,
.side {
  max-width: 45px;
  margin-left: 5px;
}
.address1 {
  width: 100%;
}
@media (max-width: 768px) {
  .address1container {
    flex-wrap: wrap;
  }
}
