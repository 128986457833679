@import "../../../../global.scss";
.coinsuredconatiner {
  margin-top: 15px;
}
.addcoinsured {
  margin: 15px 0;
  color: green;
  text-transform: uppercase;
  cursor: pointer;
}
