@import "../../../global.scss";

.flowbuttons.flowtwobuttons {
  max-width: 443px;
}
@media (max-width: $breakpoint-med) {
  .flowbuttons {
    width: calc(100% + 20px) !important;
    margin: 10px -10px 0 0;
  }
  .flowbuttons.flowtwobuttons {
    width: 100% !important;
    max-width: none;
    button {
      max-width: 50%;
    }
  }
}
