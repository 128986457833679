.modal {
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: var(--modal_bgcolor); /* Black w/ opacity */
  flex-direction: column;
}
.show{
  display:flex;
}
.hide{
  display:none;
}
.modalcontentcontainer {
  margin: 25px auto; /* 15% from the top and centered */
  
  max-width:640px;
  width:100%
}

.cancelicon {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  span {
    padding: 25px 25px 0;
  }
  svg {
    width: 25px;
    height: 25px;
    &:hover {
      cursor: pointer;
    }
  }
}
.modalbody{
  max-width:640px;
  width:100%
}