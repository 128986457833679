@import "../../global.scss";

.educationlist {
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.102));
  overflow-x: auto;
  td,
  th {
    padding-left: 10px;
    color: var(--themecolor);
  }
  .divider {
    box-shadow: 0 4px 4px -4px var(--form_box_color);
  }
  table {
    border-collapse: collapse;
  }

  th {
    text-align: left;
    font-weight: 550;
  }
  td {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .showbuttoncolumn {
    padding-right: 10px;
  }
  td div.subjects {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    flex: auto;
    max-width: 150px;
  }
  .header {
    background-color: var(--form_bgcolor);
  }
}
.readonlyeducationlist {
  padding: 5px 0;
  min-height: 24px;
  ul {
    padding-left: 20px;
    list-style: none;
  }
  ul li::before {
    content: "\2022";
    color: var(--themecolor);
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
}

.createnewbutton {
    flex-direction: column;
    justify-content: flex-end;
    display: flex;
    padding-right: 10px;
    padding-top: 37px;
}
.createnewbutton button {
    align-self: flex-end;
}
