@import "../../../global.scss";
.consentlist {
  padding: 0;
  li {
    list-style: none;
    font-weight: 700;
  }
}
.consentboxcontainer {
  display: flex;
  flex-direction: row;
  .consenterror {
    display: flex;
    color: red;
    padding-top: 5px;
    padding-left: 3px;
    font-size: 18px;
  }
  p {
    max-width: 100%;
    width: 100%;
    word-break: break-word;
  }
}
.consentcustomlabel {
  margin-left: 10px;
}
.optionalconsent {
  flex-direction: column;
}
