@import "../../../global.scss";

.yesnolist{
    margin:auto;
    width:80%;
}
.stepText {
    padding: 38px 0;
}
.stepTitle {
    padding: 0;
}
.stepDescription {
    padding-top: 18px;
}