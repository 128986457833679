@import "../../../global.scss";

.wrapperborder {
    display: flex;
    padding-top: 22px;
    color: var(--themecolor);
    flex-direction: column;
    margin-bottom:20px;
}
.wrappercontainer {
    display: flex;
    flex-flow: column;
    position: relative;
}
.withbackbar{
    padding-top:60px;
  }
.updateloader {
    display: flex;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    justify-content: center;
    align-items: center;
    min-height: 50px;
    margin: 20px 0;
}
@media (max-width: $breakpoint-med) {
    .wrapperborder {
        padding-right: 0px;
    }
   
  }