.toplabel_title {
    display: flex;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
}
.toplabel_text {
    display: flex;
    font-size: 16px;
    margin-bottom: 10px;
    width: 70%;
}
.toplabelcontainer{
  position:relative;
}
@media (max-width: 840px) {
    .toplabel_text {
        font-size: 14px;
        line-height: 36px;
        padding-left: 10px;
    }
}
