@import "../../../../global.scss";

.container {
    display: flex;
    width: 100%;
    padding: 15px 0px 5px 15px;
    margin-bottom: 2px;
    color: var(--themecolor);
}

.deliverytitle {
    display: flex;
    flex-grow: 1;
    padding-top: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.buttoncontainer {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
}

.checked {
    display: flex;
    accent-color: var(--themecolor);
}
.unChecked {
    display: flex;
}


.buttons {
    display: flex;
    flex-direction: row;

    > * {
        padding-right: 10px;
    }
}
.buttonText {
    display: flex;
    justify-content: flex-end;
}

@media (max-width: 840px) {
    .container {
        flex-direction: column;
    }
    .deliverytitle {
        padding-bottom: 6px;
    }
    .buttoncontainer {
        justify-content: start
    }
}

/*input[type="radio"]:disabled:checked {
    -webkit-appearance: none;
    display: inline-block;
    width: 12px;
    height: 12px;
    padding: 0px;
    background-clip: content-box;
    border: 2px solid #bbbbbb;
    background-color: green;
    border-radius: 50%;
    opacity: 0.6;
}*/
