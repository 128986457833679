.label,
.labelwithdescription {
  font-size: 16px;
  font-weight: 700;
  line-height: 18.96px;
  margin-bottom:11px;
}
.description {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;  
  margin-bottom:11px;
  max-width:453px;
}
.label{
  margin-bottom:11px;
}
.labelwithdescription{
  margin-bottom:8px;
}
