@import "../../../../global.scss";
.fieldcontainer{
   display:flex;
   flex-direction: row;

}
.formfield{
   display:flex;
   flex-direction: column;
   width:100%

}
.addpadding{
   padding-right:5px;
}