@import "../../../global.scss";
.questionfield {
  div > div > span {
    font-size: 16px;
  }
  
}
.questionfield>div:nth-child(1){    
    margin: 15px 0 3px;
}
