@import "../../../../variables.scss";
.overview {
  min-height: 198px;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 25px;
  background-color: var(--white);

  flex-wrap: wrap;

  .main {
    flex-grow: 1;
    width: 70%;
  }
  .detail {
    width: 30%;
    flex-grow: 1;
  }
  .openbutton {
    position: relative;
   display:flex;
   margin-bottom:-25px;
   justify-content: center;
   width:100%;
    svg {
      cursor: pointer;
      &:nth-child(1) {
        position: absolute;
        bottom: 0;
      
       
      }
      &:nth-child(2) {
        position: absolute;
        bottom: 2px;
        left:12.5px;
        path {
          stroke: #ffffff;
        }
      }
    }
  }
  .openbutton.open{
    svg:nth-child(2){
      transform: rotate(180deg);}
  }
  .openicon{
    width:40px;
    position:relative;
  }
}
@media (max-width: $breakpoint-med) {
  .overview {
    height: auto;
    flex-direction: column;
    .detail {
      width: 100%;
    }
    .main {
      width: 100%;
    }
  }
  
}
