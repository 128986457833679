$pink: var(--pink);
$red: var(--red);
$form_bgcolor: var(--form_bgcolor);
$form_disabled_bgcolor: var(--form_disabled_bgcolor);
$form_box_color: var(--form_box_color);
$form_bgcolor__hover: var(--form_bgcolor__hover);
$width: 100%;
$gutter: 0%;
$breakpoint-small: 576px;
$breakpoint-med: 768px;
$breakpoint-large: 992px;
$breakpoint-xlarge: 1200px;
$container-padding: 82px;
$container-margin: -82px;
$container-padding-med: 20px;
$container-margin-med: -20px;