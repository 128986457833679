@import "../../../global.scss";

.toptitlecontainer {
  text-align: center;
}
.wizardcontent {
  width: 443px;
  margin-left: auto;
  margin-right: auto;
}
.canceldiv {
  text-decoration: underline;
  cursor: pointer;
  display: flex;
  height: 70px;
  padding-bottom: 10px;
  align-items: center;
}
.cancelicon {
  height: 20px;
  width: 20px;
  margin-right: 20px;
  align-self: center;

  path {
    stroke: var(--themecolor);
  }
}
.wizardmain {
  padding-top: 61px;
}

.stepform{
  padding-bottom:37px;
}
.progress {
  font-size: 18px;
  line-height: 21px;
  color: var(--themecolor);
  display: flex;
  justify-content: center;
  padding-bottom: 23px;
  border-bottom: 1px solid var(--darkbeige);

  .active {
    color: var(--white);
    background-color: var(--themecolor);
    border-radius: 50%;
  }
  .progressnumber {
    display: flex;
    height: 33px;
    width: 33px;
    margin: 0 16px;
    justify-content: center;
    align-items: center;
  }
}
.wizardinfo {
  width: 549px;
}
.wizardform {
  padding-right: 82px;
}
.emptyflowbutton {
  display: none;
}
@media (max-width: $breakpoint-med) {
  .wizardinfo {
    display: none;
  }
  .wizardform {
    padding-right: 0;
  }
  .wizardcontent {
    width: 100%;
  }
  .canceldiv {
    height: 30px;
    padding-bottom: 30px;
  }
  .stepform{
    padding-bottom:65px;
  }
  .emptyflowbutton {
    width: 50%;
    background-color: var(--flowbackbuttoncolor);
    display: flex;
  }
}
