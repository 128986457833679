@import "../../../global.scss";

.buttonscontainer {
    margin-top: 15px;
    justify-content: flex-end;
    display: flex;
}

.name {
    font-weight: 800;
    line-height: 26px;
    font-size: 22px;
    margin-top: 15px;
}

.insurancecard {
    margin-bottom: 15px;
}
