@import "../../global.scss";

.infobox {
  margin-right: 82px;
}

@media (max-width: $breakpoint-med) {
  .infobox {
    margin-right: 20px;
  }
}
