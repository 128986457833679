@import "../../global.scss";

.errorMsgContainer,
.content {
  padding-left: 15px;
}

.buttoncontainer {
  
  padding-top:15px;
  text-align: right;
}
