@import "../../../../global.scss";

.element {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.header {
    width: 100%;
    display: flex;
    padding: 0px 0px 0px 15px;
    flex-direction: row;
    line-height: 0.8;
    border-bottom: 1px solid;
    border-color: var(--themecolor);
    min-height: 56px;

    .headertitle {
        display: flex;
        width: 50%;
        width: 80%;
        margin: 0;
        align-items: center;
    }

    .arrow {
        display: flex;
        width: 50%;
        justify-content: flex-end;
        align-items: center;
        padding-right: 15px;
        svg {
            width: 1em;
            height: 1em;
        }
    }
}
.headeropen {
    border-bottom: 1px solid;
}
.collapseelements {
    min-height: 40px;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;
}

.collapseelement {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-bottom: 1px solid;
    border-color: var(--darkbeige);
    margin: 0px 15px 0px 15px;
    padding-top: 5px;
    padding-bottom: 5px;

    .title {
        display: flex;
        font-weight: 800;
        font-size: 16px;
        flex-grow: 1;

        .helpericon {
            margin-left: 10px;
            position: relative;
        }
    }

    .text {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 16px;
        text-align: right;
    }
}

.collapseelement:last-child {
    border-bottom: none;
}
