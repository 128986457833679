@import "../../global.scss";

.reduction {
  width: 100%;
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
}
.reductioncontainer {
  padding: 40px;
}
.datepickercontainer {
  display: flex;
  flex-direction: column;
}
.dategroup {
  display: flex;
  flex-direction: column;
  min-width: 250px;
  margin-bottom: 15px;
}
.reductioncontent,
.datepickercontainer {
  margin: 25px 0;
}

.dategroup {
  padding-bottom:30px;
}
@media (max-width: 860px) {
  .datepickercontainer {
    flex-wrap: wrap;
  }
  .dategroup {
    min-width: 100%;
  }
}