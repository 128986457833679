@import "../../../global.scss";
.withButton {
  padding-bottom: 37px;
}

.formtitle {
  font-weight: 400;
  font-size: 20px;
  line-height: 23.7px;
}

@media (max-width: $breakpoint-med) {
  .withButton {
    padding-bottom: 65px;
  }
}
